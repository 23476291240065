import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MasterData from "../views/masterData/MasterData.vue";
import store from "../store";
import { afterLogout } from "@/utils/requestFunction.js";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "/",
    redirect: "/masterData/terminal/list",
    component: MasterData,
    children: [
      {
        name: "数据标签管理",
        path: "/masterData/qualifications",
        meta: {
          hidden: false, title: "数据标签管理", breadcrumb: [
            { title: "数据标签管理" },
          ],
        },
        component: () => import("../views/masterData/qualifications/Qualifications.vue"),
      },
    ]
  },
  {
    path: "/contactData",
    name: "触点管理",
    redirect: "/masterData/contacts",
    component: MasterData,
    children: [
      {
        name: "触点管理",
        path: "/masterData/contacts",
        meta: {
          hidden: false, title: "触点管理", breadcrumb: [
            { title: "触点管理" },
          ],
        },
        component: () => import("../views/masterData/contact/Contact.vue"),
      },
      {
        path: "/contactData/batchImport",
        component: () => import("../views/masterData/contact/modules/BatchImport.vue"),
      },
    ]
  },
  {
    path: "/holidayManage",
    name: "假期余额",
    redirect: "/masterData/contactHoliday",
    component: MasterData,
    children: [
      {
        name: "假期余额",
        path: "/masterData/contactHoliday",
        meta: {
          hidden: false, title: "假期余额", breadcrumb: [
            { title: "假期余额" },
          ],
        },
        component: () => import("../views/masterData/HolidayManage/List.vue"),
      },
      {
        path: "/contactHoliday/batchImport",
        component: () => import("../views/masterData/HolidayManage/modules/BatchImport.vue"),
      },
    ]
  },
  {
    path: "/masterData",
    name: "基础数据管理",
    redirect: "/masterData/terminal/list",
    component: MasterData,
    meta: { hidden: false, title: "基础数据管理" },
    children: [
      {
        path: "/masterData/terminal",
        redirect: "/masterData/terminal/list",
        name: "终端管理",
        component: () => import("../views/masterData/master/Terminal.vue"),
        meta: { hidden: false, title: "终端管理", key: 'MenuBasic_pg_basiData_terminal' },
        children: [
          {
            name: "终端列表",
            path: "/masterData/terminal/list",
            meta: { hidden: false, title: "终端列表", requiresAuth: true, key: 'MenuBasic_pg_basiData_terminal_list' },
            component: () =>
              import("../views/masterData/master/TerminalList.vue"),
          },
          {
            name: "终端信息",
            path: "/masterData/terminal/details/:id",
            component: () =>
              import("../views/masterData/master/TerminalDetails.vue"),
            meta: {
              hidden: true,
              title: "终端信息",
              key: 'MenuBasic_pg_basiData_terminal_details',
              breadcrumb: [
                { title: "基础数据管理" },
                { title: "终端管理" },
                { title: "终端列表", path: "/masterData/terminal/list" },
                { title: "终端信息" },
              ],
            },
          },
          {
            name: "终端参数设置",
            path: "/masterData/terminal/parameter",
            component: () =>
              import("../views/masterData/master/TerminalParameters.vue"),
            meta: {
              hidden: false, title: "终端参数设置",
              key: 'MenuBasic_pg_basiData_terminal_parameter'
            },
          },
          {
            name: "批量新增",
            path: "/masterData/terminal/batchImport",
            component: () =>
              import("../views/masterData/master/BatchImport.vue"),
            meta: { hidden: true, title: "批量新增", key: 'MenuBasic_pg_basiData_terminal_batchImport' },
          },
          {
            name: "快速匹配",
            path: "/masterData/terminal/batchImportCheck",
            component: () =>
              import("../views/masterData/master/BatchImport.vue"),
            meta: { hidden: true, title: "快速匹配", key: 'MenuBasic_pg_basiData_terminal_batchImport' },
          },
          {
            name: "批量编辑",
            path: "/masterData/terminal/batchImportEdit",
            component: () =>
              import("../views/masterData/master/BatchImport.vue"),
            meta: { hidden: true, title: "批量编辑", key: 'MenuBasic_pg_basiData_terminal_batchImport' },
          },
          {
            name: "智能补全",
            path: "/masterData/terminal/batchImportMatch",
            component: () =>
              import("../views/masterData/master/BatchImport.vue"),
            meta: { hidden: true, title: "智能补全", key: 'MenuBasic_pg_basiData_terminal_batchImport' },
          },
        ],
      },
      {
        path: "/masterData/singleProduct",
        redirect: "/masterData/singleProduct/list",
        name: "单品管理",
        meta: { hidden: false, title: "单品管理", key: 'MenuBasic_pg_basiData_singleProduct' },
        component: () =>
          import("../views/masterData/singleProduct/SingleProduct.vue"),
        children: [
          {
            name: "单品列表",
            path: "/masterData/singleProduct/list",
            meta: { hidden: false, title: "单品列表", key: 'MenuBasic_pg_basiData_singleProduct_list' },
            component: () =>
              import("../views/masterData/singleProduct/SingleProductList.vue"),
          },
          {
            name: "单品信息",
            path: "/masterData/singleProduct/details/:id",
            meta: {
              key: 'MenuBasic_pg_basiData_singleProduct_details',
              hidden: true,
              title: "单品信息",
              breadcrumb: [
                { title: "基础数据管理" },
                { title: "单品管理" },
                { title: "单品列表", path: "/masterData/singleProduct/list" },
                { title: "单品信息" },
              ],
            },
            component: () =>
              import(
                "../views/masterData/singleProduct/SingleProductDetails.vue"
              ),
          },
          {
            name: "单品批量导入",
            path: "/masterData/singleProduct/batchImport",
            meta: { hidden: true, title: "单品批量导入", key: 'MenuBasic_pg_basiData_singleProduct_parameter' },
            component: () =>
              import(
                "../views/masterData/singleProduct/SingleProductImport.vue"
              ),
          },
          {
            name: "单品批量编辑",
            path: "/masterData/singleProduct/batchImport2",
            meta: { hidden: true, title: "单品批量编辑", key: 'MenuBasic_pg_basiData_singleProduct_parameter2' },
            component: () =>
              import(
                "../views/masterData/singleProduct/SingleProductImport.vue"
              ),
          },
          {
            name: "单品参数设置",
            path: "/masterData/singleProduct/parameter",
            meta: { hidden: false, title: "单品参数设置", key: 'MenuBasic_pg_basiData_singleProduct_batchImport' },
            component: () =>
              import(
                "../views/masterData/singleProduct/SingleProductParameters.vue"
              ),
          },
        ],
      },
      {
        name: "品牌管理",
        path: "/masterData/brand",
        meta: { hidden: false, title: "品牌管理", key: 'MenuBasic_pg_basiData_brand' },
        component: () => import("../views/masterData/brand/Brand.vue"),
      },
      {
        name: "品牌批量导入",
        path: "/masterData/brand/batchImport",
        meta: {
          hidden: true, title: "品牌批量导入",
          breadcrumb: [
            { title: "基础数据管理" },
            { title: "品牌管理" },
            { title: "品牌批量导入" },
          ],
        },
        component: () =>
          import(
            "../views/masterData/brand/SingleProductImport.vue"
          ),
      },

      {
        name: "品类管理",
        path: "/masterData/category",
        meta: { hidden: false, title: "品类管理", key: 'MenuBasic_pg_basiData_category' },
        component: () => import("../views/masterData/category/Category.vue"),
      },
      {
        name: "品类批量导入",
        path: "/masterData/category/batchImport",
        meta: {
          hidden: true, title: "品类批量导入",
          breadcrumb: [
            { title: "基础数据管理" },
            { title: "品类管理" },
            { title: "品类批量导入" },
          ],
        },
        component: () =>
          import(
            "../views/masterData/category/SingleProductImport.vue"
          ),
      },
      {
        name: "渠道管理",
        path: "/masterData/channel",
        meta: { hidden: false, title: "渠道管理", key: 'MenuBasic_pg_basiData_channel' },
        component: () => import("../views/masterData/channel/Channel.vue"),
      },
      {
        name: "客户管理",
        path: "/masterData/cust",
        meta: { hidden: false, title: "客户管理", key: 'MenuBasic_pg_basiData_cust' },
        component: () => import("../views/masterData/cust/CustList.vue"),
      },

      // {
      //   path: "/masterData/cust",
      //   redirect: "/masterData/cust/list",
      //   name: "客户管理",
      //   component: () => import("../views/masterData/cust/Cust.vue"),
      //   meta: { hidden: false, title: "客户管理" },
      //   children: [
      //     {
      //       name: "客户列表",
      //       path: "/masterData/cust/list",
      //       meta: { hidden: false, title: "客户列表", requiresAuth: true },
      //       component: () =>
      //         import("../views/masterData/cust/CustList.vue"),
      //     }
      //   ],
      // },
    ],
  },
  //单独app页面

  {
    path: "/terminalApp",
    component: () => import("../views/masterData/master/TerminalList.vue"),
  },
  {
    path: "/terminalApp/details/:id",
    component: () => import("../views/masterData/master/TerminalDetails.vue"),
  },
  {
    path: "/terminalApp/batchImport",
    component: () => import("../views/masterData/master/BatchImport.vue"),
  },
  {
    path: "/singleProductApp",
    component: () =>
      import("../views/masterData/singleProduct/SingleProductList.vue"),
  },
  {
    path: "/singleProductApp/details/:id",
    component: () =>
      import("../views/masterData/singleProduct/SingleProductDetails.vue"),
  },
  {
    path: "/singleProductApp/batchImport",
    component: () =>
      import("../views/masterData/singleProduct/SingleProductImport.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.state.isLogin === false) {
    afterLogout().then((res) => {
      if (res.data.success) {
        store.commit("changeisLogin", true);
        next();
      }
    });
  } else {
    next();
  }
});
export default router;
